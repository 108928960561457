import { combineReducers } from 'redux';
import createDux from './lib/createDux';
import listDux from './lib/listDux';


const { reducer: $list, actions: list, epics: listEpics } = listDux({
  name: 'auditComments',
  url: '/api/audits/comment',
});

const { reducer: $create, actions: create, epics: createEpics } = createDux({
  name: 'auditComments',
  url: '/api/audits/comment',
});


export const actions = { list, create };
export const epics = [...listEpics, ...createEpics,];
export default combineReducers({ $list, $create, });