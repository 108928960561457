import { combineReducers } from 'redux';
import listDux from './lib/listDux';


const processResponse = ([{ response = [], status,}, [{payload:{query, isFetching}}]] = []) => {
  if (status == 200)
    return { items: response.data, count: response.data?.length, query, isFetching };
  return { error: 'Server Error', errorMessage: 'Server Error' , query, isFetching};
};

const { reducer: $monthlyShipmentsList, actions: monthlyShipmentsList, epics: monthlyShipmentsListEpics } = listDux({
  name: 'monthlyShipmentsQuery',
  url: '/api/reporting/v2/monthlyShipmentsQuery/',
  processResponse,
});

const { reducer: $shipmentsByOriginList, actions: shipmentsByOriginList, epics: shipmentsByOriginListEpics } = listDux({
  name: 'shipmentsByOriginQuery',
  url: '/api/reporting/v2/shipmentsByOriginQuery/',
  processResponse,
});

const { reducer: $shipmentsByDestinationList, actions: shipmentsByDestinationList, epics: shipmentsByDestinationListEpics } = listDux({
  name: 'shipmentsByDestinationQuery',
  url: '/api/reporting/v2/shipmentsByDestinationQuery/',
  processResponse,
});

const { reducer: $shipmentsByCarriersList, actions: shipmentsByCarriersList, epics: shipmentsByCarriersListEpics } = listDux({
  name: 'shipmentsByCarriersQuery',
  url: '/api/reporting/v2/shipmentsByCarriersQuery/',
  processResponse,
});

const { reducer: $shipmentsByForwardersList, actions: shipmentsByForwardersList, epics: shipmentsByForwardersListEpics } = listDux({
  name: 'shipmentsByForwardersQuery',
  url: '/api/reporting/v2/shipmentsByForwardersQuery/',
  processResponse,
});


const { reducer: $shipmentsTeusOverTimeList, actions: shipmentsTeusOverTimeList, epics: shipmentsTeusOverTimeListEpics } = listDux({
  name: 'shipmentAndTEUCountByDateQuery',
  url: '/api/reporting/v2/shipmentAndTEUCountByDateQuery/',
  processResponse,
});

const { reducer: $shipmentGateOutTimeList, actions: shipmentGateOutTimeList, epics: shipmentGateOutTimeListEpics } = listDux({
  name: 'dischargeTimeQuery',
  url: '/api/reporting/v2/dischargeTimeQuery/',
  processResponse,
});

const { reducer: $shipmentsByRouteList, actions: shipmentsByRouteList, epics: shipmentsByRouteListEpics } = listDux({
  name: 'shipmentsBetweenPolPodQuery',
  url: '/api/reporting/v2/shipmentsBetweenPolPodQuery/',
  processResponse,
});

const { reducer: $portToPortTransitTimeList, actions: portToPortTransitTimeList, epics: portToPortTransitTimeListEpics } = listDux({
  name: 'portToPortTransitTimeQuery',
  url: '/api/reporting/v2/portToPortTransitTimeQuery/',
  processResponse,
});



const { reducer: $dwellTimeByPolAndCarrierList, actions: dwellTimeByPolAndCarrierList, epics: dwellTimeByPolAndCarrierListEpics } = listDux({
  name: 'dwellTimeByPolAndCarrierQuery',
  url: '/api/reporting/v2/dwellTimeByPolAndCarrierQuery/',
  processResponse,
});


const { reducer: $onTimePerformanceByCarrierList, actions: onTimePerformanceByCarrierList, epics: onTimePerformanceByCarrierListEpics } = listDux({
  name: 'onTimePerformanceByCarrierQuery',
  url: '/api/reporting/v2/onTimePerformanceByCarrierQuery/',
  processResponse,
});

const { reducer: $overallCarrierArrivalPerformanceList, actions: overallCarrierArrivalPerformanceList, epics: overallCarrierArrivalPerformanceListEpics } = listDux({
  name: 'overallCarrierArrivalPerformanceQuery',
  url: '/api/reporting/v2/carrierPerformanceQuery/',
  processResponse,
});

const { reducer: $overallCarrierDestinationPerformanceList, actions: overallCarrierDestinationPerformanceList, epics: overallCarrierDestinationPerformanceListEpics } = listDux({
  name: 'overallCarrierDestinationPerformanceQuery',
  url: '/api/reporting/v2/carrierPerformanceQuery/',
  processResponse,
});

const { reducer: $performanceByCarrierArrivalList, actions: performanceByCarrierArrivalList, epics: performanceByCarrierArrivalListEpics } = listDux({
  name: 'performanceByCarrierArrivalQuery',
  url: '/api/reporting/v2/carrierPerformanceQuery/',
  processResponse,
});

const { reducer: $carrierDestinationPerformanceList, actions: carrierDestinationPerformanceList, epics: carrierDestinationPerformanceListEpics } = listDux({
  name: 'carrierDestinationPerformanceQuery',
  url: '/api/reporting/v2/carrierPerformanceQuery/',
  processResponse,
});

const { reducer: $carrierPerformanceByOriginList, actions: carrierPerformanceByOriginList, epics: carrierPerformanceByOriginListEpics } = listDux({
  name: 'carrierPerformanceByArrivalQuery',
  url: '/api/reporting/v2/carrierPerformanceQuery/',
  processResponse,
});

const { reducer: $carrierPerformanceByDestinationList, actions: carrierPerformanceByDestinationList, epics: carrierPerformanceByDestinationListEpics } = listDux({
  name: 'carrierPerformanceByDestinationQuery',
  url: '/api/reporting/v2/carrierPerformanceQuery/',
  processResponse,
});

const { reducer: $carrierTransitTimeAccuracyList, actions: carrierTransitTimeAccuracyList, epics: carrierTransitTimeAccuracyListEpics } = listDux({
  name: 'carrierTransitTimeAccuracyQuery',
  url: '/api/reporting/v2/carrierTransitTimeAccuracyQuery/',
  processResponse,
});

const { reducer: $dwellTimePerformancebyPolList, actions: dwellTimePerformancebyPolList, epics: dwellTimePerformancebyPolListEpics } = listDux({
  name: 'dwellTimeQuery',
  url: '/api/reporting/v2/dwellTimeQuery/',
  processResponse,
});

const { reducer: $carrierArrivalPerformanceList, actions: carrierArrivalPerformanceList, epics: carrierArrivalPerformanceListEpics } = listDux({
  name: 'carrierArrivalPerformanceQuery',
  url: '/api/reporting/v2/carrierArrivalPerformanceQuery/',
  processResponse,
});

const { reducer: $carrierLeadTimeList, actions: carrierLeadTimeList, epics: carrierLeadTimeListEpics } = listDux({
  name: 'carrierLeadTimeQuery',
  url: '/api/reporting/v2/carrierLeadTimeQuery/',
  processResponse : ([{ response = [], status,}, [{payload:{query, isFetching}}]] = []) => {
    if (status == 200)
      return { items: response.data, count: response.data[0].totalCount, query, isFetching };
    return { error: 'Server Error', errorMessage: 'Server Error' , query, isFetching};
  },
});

const { reducer: $rfqByPortLoadingList, actions: rfqByPortLoadingList, epics: rfqByPortLoadingListEpics } = listDux({
  name: 'rfqByPortLoadingList',
  url: '/api/rfq/rfqs/port-summary/',
  processResponse,
});

const { reducer: $rfqByPortDestinationList, actions: rfqByPortDestinationList, epics: rfqByPortDestinationListEpics } = listDux({
  name: 'rfqByPortDestinationList',
  url: '/api/rfq/rfqs/port-summary/',
  processResponse,
});

const { reducer: $rfqByVendorList, actions: rfqByVendorList, epics: rfqByVendorListEpics } = listDux({
  name: 'rfqByVendorList',
  url: '/api/rfq/rfqs/vendor-summary/',
  processResponse,
});

const { reducer: $rfqVendorParticipationList, actions: rfqVendorParticipationList, epics: rfqVendorParticipationListEpics } = listDux({
  name: 'rfqVendorParticipationList',
  url: '/api/rfq/quotes/vendor-participation-summary/',
  processResponse,
});

export const actions = { monthlyShipmentsList, shipmentsByOriginList, shipmentsByDestinationList, shipmentsByCarriersList, shipmentsByForwardersList, shipmentsTeusOverTimeList, shipmentGateOutTimeList, shipmentsByRouteList, portToPortTransitTimeList, dwellTimeByPolAndCarrierList, onTimePerformanceByCarrierList, carrierArrivalPerformanceList, carrierDestinationPerformanceList, overallCarrierArrivalPerformanceList, carrierDestinationPerformanceList, overallCarrierDestinationPerformanceList, carrierPerformanceByOriginList, carrierPerformanceByDestinationList, carrierTransitTimeAccuracyList, dwellTimePerformancebyPolList, performanceByCarrierArrivalList, carrierLeadTimeList, rfqByPortLoadingList,  rfqByPortDestinationList, rfqByVendorList, rfqVendorParticipationList };
export const epics = [...monthlyShipmentsListEpics, ...shipmentsByOriginListEpics, ...shipmentsByDestinationListEpics, ...shipmentsByCarriersListEpics, ...shipmentsByForwardersListEpics, ...shipmentsTeusOverTimeListEpics, ...shipmentGateOutTimeListEpics, ...shipmentsByRouteListEpics, ...portToPortTransitTimeListEpics, ...dwellTimeByPolAndCarrierListEpics, ...onTimePerformanceByCarrierListEpics, ...carrierArrivalPerformanceListEpics, ...carrierDestinationPerformanceListEpics, ...overallCarrierArrivalPerformanceListEpics, ...overallCarrierDestinationPerformanceListEpics, ...carrierPerformanceByOriginListEpics, ...carrierPerformanceByDestinationListEpics, ...carrierTransitTimeAccuracyListEpics, ...dwellTimePerformancebyPolListEpics, ...performanceByCarrierArrivalListEpics, ...carrierLeadTimeListEpics, ...rfqByPortLoadingListEpics,  ...rfqByPortDestinationListEpics, ...rfqByVendorListEpics, ...rfqVendorParticipationListEpics ];

export default combineReducers({ $monthlyShipmentsList, $shipmentsByOriginList, $shipmentsByDestinationList, $shipmentsByCarriersList, $shipmentsByForwardersList,$shipmentsTeusOverTimeList, $shipmentGateOutTimeList, $shipmentsByRouteList, $portToPortTransitTimeList, $dwellTimeByPolAndCarrierList, $onTimePerformanceByCarrierList, $carrierArrivalPerformanceList, $carrierDestinationPerformanceList, $overallCarrierArrivalPerformanceList, $overallCarrierDestinationPerformanceList, $carrierPerformanceByOriginList, $carrierPerformanceByDestinationList, $carrierTransitTimeAccuracyList, $dwellTimePerformancebyPolList, $performanceByCarrierArrivalList, $carrierLeadTimeList, $rfqByPortLoadingList, $rfqByPortDestinationList, $rfqByVendorList, $rfqVendorParticipationList });
