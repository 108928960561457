import {combineReducers} from 'redux';

import {ajaxEpic, getDetailsOnUpdateEpic} from './lib/epic';
import dux from './lib/dux';
import listDux from './lib/listDux';
import detailsDux from './lib/detailsDux';
import createDux from './lib/createDux';
import updateDux from './lib/updateDux';

const {reducer: $list, actions: list, epics: listEpics} = listDux({
  name: 'rfqs',
  url: '/api/rfq/rfqs/',
});

const {reducer: $details, actions: details, epics: detailsEpics} = detailsDux({
  name: 'rfqs',
  url: '/api/rfq/rfqs/',
});

const {reducer: $create, actions: create, epics: createEpics} = createDux({
  name: 'rfqs',
  url: '/api/rfq/rfqs/',
});

const {reducer: $update, actions: update, epics: updateEpics} = updateDux({
  name: 'rfqs',
  url: '/api/rfq/rfqs/',
  updatedRecordField: 'rfq',
});

const {reducer: $summary, actions: summary, epics: summaryEpics} = dux({
  name: 'rfqs.$summary',
  initialState: {isFetching: false,},
  actions: [
    {name: 'get', getPayload: () => ({isFetching: true})},
    {name: 'set', getPayload: ({error, errorMessage='', ...summary}) => ({error, errorMessage, ...summary, isFetching: false})},
  ],
  epics: [
    ({getActionType, getAction}) => ajaxEpic({inActionType: getActionType('get'), outAction: getAction('set'), withLatestState: true, request: {url: '/api/rfq/rfqs/summary', method: 'GET'},}),
  ],
});

export const actions = {list, details, create, update, summary};
export const epics = [...listEpics, ...detailsEpics, ...createEpics, ...updateEpics, ...summaryEpics];
export default combineReducers({$list, $details, $create, $update, $summary});