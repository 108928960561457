import objectToQueryString from '../../utils/objectToQueryString';
import {ajaxEpic} from './epic';
import dux from './dux';

const listDux = ({
  name, 
  initialState={items: [], count: 0, isFetching: false, query: {},},
  actions=[],
  epics=[],
  getListPayload = (query = {}) => ({ query, [JSON.stringify(query)]: { isFetching: true,}, isFetching: true,}),
  setListPayload = ({ items = [], count = 0, error = undefined, errorMessage = '', query = {}, ...payload } = {}) => ({ ...payload, [JSON.stringify(query)]: { items, count, error, errorMessage, isFetching: false,}, items, count, error, errorMessage, isFetching: false,}),
  withLatestState=true, 
  url, 
  method='GET', 
  headers={}, 
  request={url, method, headers},
  getUrl=(url, {0: {payload: {query}={}}={}}) => `${url}?${objectToQueryString(query)}`,
  getRequest, 
  processResponse, 
  processError, 
  getOutAction, 
  ...props
}) => dux({
  name: `${name}.$list`,
  initialState,
  actions: [
    {name: 'get', getPayload: getListPayload},
    {name: 'set', getPayload: setListPayload},
    ...actions,
  ],
  epics: [
    ({getActionType, getAction}) => ajaxEpic({inActionType: getActionType('get'), outAction: getAction('set'), withLatestState, request, getUrl, getRequest, processResponse, processError, getOutAction,}),
    ...epics,
  ],
  ...props,
});

export default listDux;
