import { combineReducers } from 'redux';
import culdDux from './lib/culdDux';
import dux from './lib/dux';
import {ajaxEpic} from './lib/epic';

const { reducer: $audits, actions: audits, epics: auditsEpics } = culdDux({
  name: 'audits',
  url: '/api/audits/audit',
  updatedRecordField: null,
});

const {reducer: $summary, actions: summary, epics: summaryEpics} = dux({
  name: 'audit.$summary',
  initialState: {isFetching: false,},
  actions: [
    {name: 'get', getPayload: () => ({isFetching: true})},
    {name: 'set', getPayload: ({error, errorMessage='', ...summary}) => ({error, errorMessage, ...summary, isFetching: false})},
  ],
  epics: [
    ({getActionType, getAction}) => ajaxEpic({inActionType: getActionType('get'), outAction: getAction('set'), withLatestState: true, request: {url: '/api/audits/audit/summary', method: 'GET'},}),
  ],
});




export const actions = { ...audits, summary };
export const epics = [...auditsEpics, ...summaryEpics];
export default combineReducers({ $audits, $summary });