import React from 'react';
import { withMediaQueryProps } from '../media-query-2';
import { mediaQuery } from '../media-query';

import * as Styles from './Styles.css';

function Row({children, mediaQuery, reverse=false, alignItems, justifyContent, flexGrow, flexShrink, flexWrap, alignSelf, style={}, className='', ...rest}, ref) {
  style = {
    alignItems,
    justifyContent,
    flexGrow,
    flexShrink,
    flexWrap,
    alignSelf,
    ...style,
  }
  return (
    <div {...{ref, style, className: `${Styles.row} ${reverse ? Styles.reverse : ''} ${className}`, ...rest}}>{children}</div>
  )
}


export default withMediaQueryProps(mediaQuery(React.forwardRef(Row)));
