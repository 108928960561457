
import { combineReducers } from 'redux';
import culdDux from './lib/culdDux';


const { reducer: $auditLineItems, actions: auditLineItems, epics: auditLineItemsEpics } = culdDux({
  name: 'auditLineItems',
  url: '/api/audits/line-items',
});


export const actions = { ...auditLineItems};
export const epics = [...auditLineItemsEpics,];
export default combineReducers({ $auditLineItems,});