const routes = [
  {
    equals: '/',
    app: 'dashboard',
    isProtected: true,
    activePaths: [{ path: "/", exact: true }],
  },
  {
    startsWith: ['/invoices', '/templates', '/documents',],
    app: 'documents',
    isProtected: true,
  },
  {
    startsWith: '/audits',
    app: 'audits',
    isProtected: true,
  },
  {
    startsWith: '/freightAudit',
    app: 'freightAudit',
    isProtected: true,
  },
  {
    startsWith: '/regularization',
    app: 'regularization',
    isProtected: false,
  },
  {
    startsWith: '/login',
    name: 'loginRoutingApp',
    app: 'login',
    customProps: {},
    hideHeader: true,
    hideFooter: true,
    hideSideNav: true,
  },
  {
    startsWith: ['/signup', '/update-password'],
    app: 'authV2',
    hideHeader: true,
    customProps: {},
    hideFooter: true,
    hideSideNav: true,
  },
  {
    startsWith: ['/forgot-password', '/change-password'],
    app: 'changePassword',
    hideHeader: true,
    hideFooter: true,
    hideSideNav: true,
  },
  {
    startsWith: '/account',
    app: 'customers',
    isProtected: true,
  },
  {
    startsWith: '/shipments',
    app: 'shipments',
    isProtected: true,
  },
  {
    startsWith: '/shipments/v2',
    app: 'shipmentsV2',
    isProtected: true,
  },
  {
    startsWith: '/contracts',
    app: 'contracts',
    isProtected: true,
  },
  {
    startsWith: '/insights',
    app: 'reports',
    isProtected: true,
  },
  {
    startsWith: '/insights/v2',
    app: 'reportsV2',
    isProtected: true,
  },
 
];

export default routes;