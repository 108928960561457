import { combineReducers } from 'redux';
import culdDux from './lib/culdDux';
import listDux from './lib/listDux';

const { reducer: $shipments, actions: shipments, epics: shipmentsEpics } = culdDux({
  name: 'shipments',
  url: '/api/shipments/v2/',
});

const { reducer: $shipmentInvoices, actions: shipmentInvoices, epics: shipmentInvoicesEpics } = listDux({
  name: 'shipmentInvoices',
  url: '/api/shipments/v2/shipment-invoices/'
});

export const actions = { ...shipments, shipmentInvoices};
export const epics = [...shipmentsEpics, ...shipmentInvoicesEpics];
export default combineReducers({ $shipments, $shipmentInvoices});