import { combineReducers } from 'redux';
import culdDux from './lib/culdDux';
import updateDux from './lib/updateDux';


const { reducer: $auditChecklists, actions: auditChecklists, epics: auditChecklistsEpics } = culdDux({
  name: 'auditChecklist',
  url: '/api/audits/checklist',
});

const { reducer: $verify, actions: verify, epics: verifyEpics } = updateDux({
  name: 'auditChecklist.$verify',
  url: '/api/audits/verifyChecklist/',
  updatedRecordField: null,
  getUrl: (url) => url,
  updatePayload: (_id) => ({ _id, data: {checklistId: _id}, isUpdating: true, }),
});

export const actions = { ...auditChecklists, verify };
export const epics = [...auditChecklistsEpics, ...verifyEpics];
export default combineReducers({ $auditChecklists, $verify });