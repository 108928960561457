import { combineReducers } from 'redux';
import listDux from './lib/listDux';

const { reducer: $list, actions: list, epics: listEpics } = listDux({
  name: 'users',
  url: '/api/users/',
});

const { reducer: $internalUsersList, actions: internalUsersList, epics: internalUsersListEpics } = listDux({
  name: 'internalUsers',
  url: '/api/users',
});

export const actions = { list, internalUsersList };
export const epics = [...listEpics, ...internalUsersListEpics];
export default combineReducers({ $list, $internalUsersList });