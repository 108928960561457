import React, { Suspense, lazy } from 'react';
import { Link } from 'react-router-dom';

import Column from '../column';

import MaterialIcon from './MaterialIcon';
import Circle from './SVGIcons/Circle';
import * as Styles from './Styles.css';

class IconComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { showMaterialIcon: false };
  }
  static getDerivedStateFromError(error) {
    return { showMaterialIcon: true };
  }
  render() {
    const { showMaterialIcon } = this.state;
    const { type, iconClass, theme, className, disabled, ...props } = this.props;
    if (showMaterialIcon || iconClass == 'material')
      return <MaterialIcon type={type} disabled={disabled} {...props} />;

    const SVGIcon = lazy(() => import(`./SVGIcons/${type}`));
    return (
      <Suspense fallback={<Circle className={`${Styles[theme]} ${className}`} {...props} />}>
        <SVGIcon className={`${Styles[theme]} ${className}`} {...props} />
      </Suspense>
    );
  }
}

const LinkContainer = ({ children, linkTo, ...props }) => {
  if (linkTo)
    return <Link to={linkTo} {...props}>{children}</Link>
  return <React.Fragment>{children}</React.Fragment>
}

function Icon({ type, size = 20, width, height, rotate, fill, theme, color, alignItems = 'center', justifyContent = 'center', className = '', children, linkTo, iconClass = 'material', disabled = false, props = {}, ...rest }, ref) {
  return (
    <Column ref={ref} alignItems={alignItems} justifyContent={justifyContent} style={{ width, height }} className={className} {...rest}>
      <LinkContainer linkTo={linkTo} className={Styles.link}>
        <IconComponent {...{ type, iconClass, size, rotate, fill, color, theme, disabled, ...props }} />
        {children}
      </LinkContainer>
    </Column>
  );
}

export default React.forwardRef(Icon);