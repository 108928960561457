import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';

import auth, {getUserTokenEpic, setAuthTokenEpic, getUsersEpic, authenticateEpic, getUserCompanyEpic, getEnterprisePlanEpic, signUpEpic, loginEpic, logoutEpic, logoutFromOtherTabEpic, forgotPasswordEpic, generateResetPasswordTokenEpic, getResetPasswordTokenEpic, updatePasswordEpic} from './auth';
import ports, {epics as portEpics, actions as portActions} from './ports';
import googlePlaces, {epics as googlePlaceEpics, actions as googlePlaceActions} from './googlePlaces';
import exchangeRates, {epics as exchangeRateEpics, actions as exchangeRateActions} from './exchangeRates';
import containerTypes, {epics as containerTypeEpics, actions as containerTypeActions} from './containerTypes';
import schedules, {epics as scheduleEpics, actions as scheduleActions} from './schedules';
import enquiriesV2, { epics as enquiryV2Epics, actions as enquiryV2Actions } from './enquiriesV2';
import quotes, { epics as quotesEpics, actions as quotesActions } from './quotes';
import feedback, { epics as feedbackEpics, actions as feedbackActions } from './feedback';
import rfqs, {epics as rfqEpics, actions as rfqActions} from './rfqs';
import rfqQuotes, {epics as rfqQuoteEpics, actions as rfqQuoteActions} from './rfqQuotes';
import rfqTerms, {epics as rfqTermEpics, actions as rfqTermActions} from './rfqTerms';
import rfqTermGroups, {epics as rfqTermGroupEpics, actions as rfqTermGroupActions} from './rfqTermGroups';
import companies, {epics as companyEpics, actions as companyActions} from './companies';
import users, { epics as userEpics, actions as userActions } from './users';
import ratesV3, { epics as ratesV3Epics, actions as ratesV3Actions } from './ratesV3'; 
import shipper, { epics as shipperEpics, actions as shipperAction } from './shipper';
import enterprises, {epics as enterpriseEpics, actions as enterpriseActions} from './enterprise';
import documentsV2, {epics as documentEpics, actions as documentActions} from './documentsV2';
import shipments, {epics as shipmentsEpics, actions as shipmentsActions} from './shipments';
import invoices, {epics as invoicesEpics, actions as invoicesActions} from './invoices';
import searchDialog, {getSearchHistoryEpic, setSearchHistoryEpic} from './searchDialog';
import iciciLead, {epics as iciciLeadEpics, actions as iciciLeadActions} from './iciciLead';
import pay2corp, { epics as pay2corpEpics, actions as pay2corpActions } from './pay2corp';
import quickbooks, { epics as quickbooksEpics, actions as quickbooksActions } from './quickbooks';
import zohobooks, { epics as zohobooksEpics, actions as zohobooksActions } from './zohobooks';
import setting, { setSideNavViewEpic, getSideNavViewEpic} from './setting';
import tracking, {epics as trackingEpics, actions as trackingActions} from './tracking';
import report, {epics as reportEpics, actions as reportActions} from './report';
import compose, {epics as composeEpics, actions as composeActions} from './compose';
import composeDocumentTemplate, {epics as composeDocumentTemplateEpics, actions as composeDocumentTemplateActions} from './composeDocumentTemplate';
import documentTemplate, {epics as documentTemplateEpics, actions as documentTemplateActions} from './documentTemplate';
import notificationPreferences, {epics as notificationPreferencesEpics, actions as notificationPreferencesActions} from './notificationPreferences';
import regularization, {epics as regularizationEpics, actions as regularizationActions} from './regularization';
import pdfReader, { epics as pdfReaderEpics, actions as pdfReaderActions } from './pdfReader';
import demoRequests, {epics as demoRequestsEpics, actions as demoRequestsActions} from './demoRequests';
import journeys, { epics as journeyEpics, actions as journeyActions } from './journeys';
import ldb, { epics as ldbEpics, actions as ldbActions } from './ldb';
import positions, {epics as positionEpics, actions as positionActions} from './positions';
import events, {epics as eventEpics, actions as eventActions} from './events';
import bookings, {epics as bookingEpics, actions as bookingActions} from './bookings';
import harmonizedSystemCodes, {epics as harmonizedSystemCodeEpics, actions as harmonizedSystemCodeActions} from './harmonizedSystemCodes';
import companyLocations, {epics as companyLocationEpics, actions as companyLocationActions} from './companyLocations';
import audits, {epics as auditEpics, actions as auditActions} from './audits';
import auditChecklists, {epics as auditChecklistsEpics, actions as auditChecklistActions} from './auditChecklists';
import auditComments, {epics as auditCommentEpics, actions as auditCommentActions} from './auditComments';
import auditLineItems, {epics as auditLineItemsEpics, actions as auditLineItemsActions} from './auditLineItems';

export const actions = {
  ports: portActions,
  googlePlaces: googlePlaceActions,
  containerTypes: containerTypeActions,
  exchangeRates: exchangeRateActions,
  schedules: scheduleActions,
  enquiriesV2: enquiryV2Actions,
  quotes: quotesActions,
  feedback: feedbackActions,
  rfqs: rfqActions,
  rfqQuotes: rfqQuoteActions,
  rfqTerms: rfqTermActions,
  rfqTermGroups: rfqTermGroupActions,
  companies: companyActions,
  users: userActions,
  ratesV3: ratesV3Actions, 
  shipper: shipperAction,
  enterprises: enterpriseActions,
  documentsV2: documentActions,
  shipments: shipmentsActions,
  invoices: invoicesActions,
  iciciLead: iciciLeadActions,
  pay2corp: pay2corpActions,
  quickbooks: quickbooksActions,
  zohobooks: zohobooksActions,
  report: reportActions,
  tracking: trackingActions,
  compose: composeActions,
  composeDocumentTemplate: composeDocumentTemplateActions,
  documentTemplate: documentTemplateActions,
  notificationPreferences: notificationPreferencesActions,
  regularization: regularizationActions,
  pdfReader: pdfReaderActions,
  demoRequests: demoRequestsActions,
  journeys: journeyActions,
  ldb: ldbActions,
  positions: positionActions,
  events: eventActions,
  bookings: bookingActions,
  harmonizedSystemCodes: harmonizedSystemCodeActions,
  companyLocations: companyLocationActions,
  audits: auditActions,
  auditChecklists: auditChecklistActions,
  auditComments: auditCommentActions,
  auditLineItems: auditLineItemsActions,
}

export const epic = combineEpics(
  getUserTokenEpic,
  setAuthTokenEpic,
  getUsersEpic,
  authenticateEpic,
  getUserCompanyEpic,
  getEnterprisePlanEpic,
  signUpEpic,
  loginEpic,
  logoutEpic,
  logoutFromOtherTabEpic,
  forgotPasswordEpic,
  generateResetPasswordTokenEpic,
  getResetPasswordTokenEpic,
  updatePasswordEpic,
  getSearchHistoryEpic,
  setSearchHistoryEpic,
  setSideNavViewEpic,
  getSideNavViewEpic,
  ...portEpics,
  ...googlePlaceEpics,
  ...containerTypeEpics,
  ...exchangeRateEpics,
  ...scheduleEpics,
  ...enquiryV2Epics,
  ...quotesEpics,
  ...feedbackEpics,
  ...rfqEpics,
  ...rfqQuoteEpics,
  ...rfqTermEpics,
  ...rfqTermGroupEpics,
  ...companyEpics,
  ...userEpics,
  ...ratesV3Epics, 
  ...shipperEpics,
  ...enterpriseEpics,
  ...documentEpics,
  ...shipmentsEpics,
  ...invoicesEpics,
  ...iciciLeadEpics,
  ...pay2corpEpics,
  ...quickbooksEpics,
  ...zohobooksEpics,
  ...trackingEpics,
  ...reportEpics,
  ...composeEpics,
  ...composeDocumentTemplateEpics,
  ...documentTemplateEpics,
  ...notificationPreferencesEpics,
  ...regularizationEpics,
  ...pdfReaderEpics,
  ...demoRequestsEpics,
  ...journeyEpics,
  ...ldbEpics,
  ...positionEpics,
  ...eventEpics,
  ...bookingEpics,
  ...harmonizedSystemCodeEpics,
  ...companyLocationEpics,
  ...auditEpics,
  ...auditChecklistsEpics,
  ...auditCommentEpics,
  ...auditLineItemsEpics,
);

export default combineReducers({
  auth,
  ports,
  googlePlaces,
  containerTypes,
  exchangeRates,
  schedules,
  enquiriesV2,
  quotes,
  feedback,
  rfqs,
  rfqQuotes,
  rfqTerms,
  rfqTermGroups,
  companies,
  users,
  ratesV3, 
  shipper,
  enterprises,
  documentsV2,
  shipments,
  invoices,
  searchDialog,
  iciciLead,
  pay2corp,
  quickbooks,
  zohobooks,
  setting,
  report,
  tracking,
  compose,
  composeDocumentTemplate,
  documentTemplate,
  notificationPreferences,
  regularization,
  pdfReader,
  demoRequests,
  journeys,
  ldb,
  positions,
  events,
  bookings,
  harmonizedSystemCodes,
  companyLocations,
  audits,
  auditChecklists,
  auditComments,
  auditLineItems,
});
