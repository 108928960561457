import { combineReducers } from 'redux';

import dux from './lib/dux';
import listDux from './lib/listDux';
import detailsDux from './lib/detailsDux';
import createDux from './lib/createDux';
import updateDux from './lib/updateDux';
import {ajaxEpic} from './lib/epic';

const { reducer: $list, actions: list, epics: listEpics } = listDux({
  name: 'rfqQuotes',
  url: '/api/rfq/quotes/',
});

const { reducer: $details, actions: details, epics: detailsEpics } = detailsDux({
  name: 'rfqQuotes',
  url: '/api/rfq/quotes/',
  setDetailsFromListPayload: () => { },
});

const { reducer: $create, actions: create, epics: createEpics } = createDux({
  name: 'rfqQuotes',
  url: '/api/rfq/quotes/',
});

const { reducer: $update, actions: update, epics: updateEpics } = updateDux({
  name: 'rfqQuotes',
  url: '/api/rfq/quotes/',
  switchRequests: false,
});

const {reducer: $summary, actions: summary, epics: summaryEpics} = dux({
  name: 'rfqQuotes.$summary',
  initialState: {isFetching: false,},
  actions: [
    {name: 'get', getPayload: () => ({isFetching: true})},
    {name: 'set', getPayload: ({error, errorMessage='', ...summary}) => ({error, errorMessage, ...summary, isFetching: false})},
  ],
  epics: [
    ({getActionType, getAction}) => ajaxEpic({inActionType: getActionType('get'), outAction: getAction('set'), withLatestState: true, request: {url: '/api/rfq/quotes/summary', method: 'GET'},}),
  ],
});

export const actions = { list, details, create, update, summary };
export const epics = [...listEpics, ...detailsEpics, ...createEpics, ...updateEpics, ...summaryEpics];
export default combineReducers({ $list, $details, $create, $update, $summary });